//
//
//
//
//
//
//

export default {
    name: "ListProductPrice",
    props: {
        price: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    computed: {
        hasSale() {
            return this.price.first !== this.price.sale
        }
    }
}
