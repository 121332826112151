//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ResponsiveImage from "@/components/common/ResponsiveImage";
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "ProductColorCarousel",
    components: {ResponsiveImage, VueSlickCarousel},
    props: {
        colors: {
            type: Array,
            default: function () {
                return [];
            }
        },
        vendor: {
            type: String,
            default: ''
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    data() {
        return {
            settings: {
                dots: false,
                variableWidth: true,
                infinite: false,
                slidesToShow:   7,
                slidesToScroll: 6,
                responsive: [
                    {
                        breakpoint: 1440,
                        settings: {
                            slidesToShow: 6,
                            slidesToScroll: 5,
                        }
                    },
                ]
            },
            activeSlide: 0
        }
    },
    computed: {
        slidesToShow () {
            return this.isDesktop ?  7 : 6
        },
        isDesktop() {
            return this.laptopSmallUp
        },
        colorsLength() {
            return this.colors.length
        },
        showPrevBtn() {
            return this.activeSlide > 1
        },
        showNextBtn() {
            return  (this.slidesToShow + this.activeSlide) < this.colorsLength
        }
    },
    methods: {
        getImageUrl(colorData){
            const image  = (colorData?.imagesMainList?.plp ?? {})
            return ImageHelper.getMediaStructure(
                image.id,
                AppConfig.image.fileExtensions.catalog,
                (image?.title ?? ''),
                (image?.alt ?? ''),
                ImageSizes.catalogSmallColor,
                30)
        },
        showNext() {
            this.$refs.carousel.next()
        },
        showPrev() {
            this.$refs.carousel.prev()
        },
        beforeChange(oldIndex, newIndex) {
            this.activeSlide = newIndex
        },
        mouseenter(item) {
            this.$emit('updateHover', item)
        },
        mouseleave() {
            this.$emit('updateHover', undefined)
        }
    },
}
